import React, {useEffect, useState} from 'react';
import axios from "axios";

const AllPaterns = () => {
    const [paterns, setPaterns] = useState([]);
    const [paternsFiltered, setPaternsFiltered] = useState([]);
    const [paternCats, setPaterncats] = useState([]);
    const [categorySelected, setCategorySelected] = useState("");
    const [paternMove, setPaternMove] = useState(false)

    useEffect(() => {
        axios.get(`http://plaques.cagu0944.odns.fr/api/app.php?getAllPaterns=true&t=${Date.now()}`)
            .then(response => {
                setPaterns(response.data);
                setPaternsFiltered(response.data);
                setPaternMove(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [paternMove]);

    useEffect(() => {

        const array = [];
        paterns.forEach(element => {
            if (!array.includes(element.category)) {
                array.push(element.category);
            }
        });
        setPaterncats(array);

    }, [paterns]);

    return (
        <section className="all_paterns_sections">
            <h1 style={{textAlign: "center"}}>Listes des paternes</h1>
            {/* Fait un système de recherche sur le nom, entreprise */}
            <div className="search_paterns">
                <input className="search_paterns_input" type="text" placeholder="Rechercher un patern"
                       onChange={(e) => {
                           setPaternsFiltered(paterns.filter(patern => patern.name.toLowerCase().includes(e.target.value.toLowerCase()) || patern.entreprise.toLowerCase().includes(e.target.value.toLowerCase()) || patern.type.toLowerCase().includes(e.target.value.toLowerCase()) || patern.category.toLowerCase().includes(e.target.value.toLowerCase())));

                       }}/>
            </div>
            <div className="allPaterns">
                <p>Filter par catégories</p>
                <select className="paterns-list" onChange={(e) => {
                    if (e.target.value === "-- Liste des paternes --") {
                        setPaternsFiltered(paterns);
                    } else {
                        setPaternsFiltered(paterns.filter(patern => patern.category === e.target.value));
                        setCategorySelected(e.target.value);
                    }
                }}>
                    <option selected disabled>-- Liste des paternes --</option>
                    {
                        paternCats.map((item, i) => (
                            <option value={item}>{item}</option>
                        ))
                    }
                </select>
                <p>Liste des paternes déjà enregistrés pour les produits {categorySelected} </p>

                <table>
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Entreprise</th>
                        <th>Type</th>
                        <th>Image</th>
                        {
                            categorySelected === "carte" || categorySelected === "portecle" ?
                                <th>Image verso</th>
                                : null
                        }
                        <th>Actions</th>
                    < /tr>
                    </thead>
                    <tbody>
                    {
                        paternsFiltered.map((patern, i) => (
                            <tr key={i}>
                                <td>{patern.name}</td>
                                <td>{patern.entreprise}</td>
                                <td>{patern.type}</td>
                                <td className="image">
                                    <img
                                        src={`http://plaques.cagu0944.odns.fr/api/${patern.image}`}
                                        alt=""/>
                                    <span className="image_loading text-success">Chargement de l'image...</span>
                                    <p>Voir le paterne</p>
                                </td>
                                {
                                    categorySelected === "carte" || categorySelected === "portecle" ?
                                        patern.verso ?
                                            <td className="image"><img
                                                style={{width: patern.patern_w, height: patern.patern_h}}
                                                src={`http://plaques.cagu0944.odns.fr/api/${patern.verso}`}
                                                alt=""/>
                                                <span className="image_loading text-success">Chargement de l'image...</span>
                                                <p>Voir le verso</p>
                                            </td>
                                            : <td className="image text-danger">Pas de verso</td>
                                        : null
                                }
                                <td>
                                    {/* <button className="btn btn-primary">Modifier</button>*/}
                                    <button className="btn btn-danger" onClick={(e) => {
                                        e.preventDefault();
                                        if (window.confirm("Voulez vous vraiment supprimer ce patern ?")) {
                                            axios.get(`http://plaques.cagu0944.odns.fr/api/appProd.php?deletePatern=true&id=${patern.id}&t=${Date.now()}`)
                                                .then(response => {
                                                    setPaternMove(true);
                                                })
                                                .catch(error => {
                                                    console.error('Error fetching data:', error);
                                                });
                                        }
                                    }}>Supprimer
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
            <style>


            </style>
        </section>
    );
};

export default AllPaterns;