import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Login from '../pages/Login';
import Layout from '../layouts/Layout';
import GeneratePlaques from '../pages/GeneratePlaques';
import RedirectionsGroups from '../pages/RedirectionsGroups';
import RedirectionList from '../pages/RedirectionList';
import AddUser from '../pages/AddUser';
import AddPatern from '../pages/AddPatern';
import GenerateQrcode from "../pages/GenerateQrcode";
import AllPaterns from "../pages/AllPaterns";

const index = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Login />} />
                <Route element={<Layout />}>
                    <Route path='/plaques' element={<GeneratePlaques />} />
                    <Route path='/qrcodes' element={<GenerateQrcode />} />
                    <Route path='/patern' element={<AddPatern />} />
                    <Route path='/allPatern' element={<AllPaterns />} />
                    <Route path='/addUser' element={<AddUser />} />
                    <Route path='/groupes'>
                        <Route index element={<RedirectionsGroups />} />
                        <Route path='redirections/:id' element={<RedirectionList />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default index;