import React from 'react';

const QrCode = ({ qrCodeList }) => {

    return (
        <>
            {
                qrCodeList
            }
        </>
    );
};

export default QrCode;