import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {NavLink} from "react-router-dom";


const RedirectionsGroups = () => {

    const [groups, setGroups] = useState([]);
    const [upGroup, setUpGroup] = useState(false);
    const [groupUp, setGroupUp] = useState(false);
    const [groupNameUp, setGroupNameUp] = useState('');
    const [groupToUp, setGroupToUp] = useState('');

    const [addGroup, setAddGroup] = useState(false);
    const [groupAdded, setGroupAdded] = useState(false);
    const [groupName, setGroupName] = useState('');

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedGroups = groups.slice(startIndex, endIndex);

    const [redirections, setRedirections] = useState([]);
    const [redirectionsToFilter, setRedirectionsToFilter] = useState([]);


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(groups.length / itemsPerPage);

    const paginationItems = [];
    for (let i = 1; i <= totalPages; i++) {
        if (i === 1 || i === totalPages || i === currentPage || i === currentPage - 1 || i === currentPage + 1) {
            paginationItems.push(
                <li
                    key={i}
                    className={`page-item ${i === currentPage ? 'active' : ''}`}
                >
                    <p
                        className="page-link"
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                    </p>
                </li>
            );
        }
        // Add ellipsis for skipped pages but avoid duplicates
        else if (i === currentPage - 2 || i === currentPage + 2) {
            paginationItems.push(
                <li key={i} className="page-item disabled">
                    <p className="page-link">...</p>
                </li>
            );
        }
    }

    useEffect(() => {
        if (localStorage.getItem('role') === 'admin') {
            axios.get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?getAllGroups=true&t=${Date.now()}`)
                .then(response => {
                    setGroups(response.data);
                    setGroupUp(false);
                    setGroupAdded(false);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
            axios.get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?getAllRedirections=true&t=${Date.now()}`)
                .then(response => {
                    setRedirections(response.data);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } else {
            axios.get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?getGroupsByUser=true&user_id=${localStorage.getItem('id')}&t=${Date.now()}`)
                .then(response => {
                    setGroups(response.data);
                    setGroupUp(false);
                    setGroupAdded(false);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [groupUp, groupAdded])

    return (
        <div className='groups-container'>
            <h1 className='text-center p-2 display-5'>Listes des groupes de redirections</h1>
            <p className='text-center' onClick={() => {
                setAddGroup(true);
            }}>Ajouter un groupe</p>
            <div className="row m-3">
                <h4 style={{textAlign: "center"}}>Rechercher un groupe de redirections</h4>
                <div className='col-12 col-md-6 mx-auto'>
                    <input type="text" className='form-control' placeholder='Rechercher ...' onChange={(e) => {
                        const groupsFiltered = groups.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
                        setGroups(groupsFiltered);
                        setCurrentPage(1);

                        if (e.target.value === '') {
                            setGroupAdded(true);
                        }
                    }}/>
                </div>
            </div>
            <nav aria-label="Page navigation">
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <p
                            className="page-link"
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Previous
                        </p>
                    </li>
                    {paginationItems}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <p
                            className="page-link"
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Next
                        </p>
                    </li>
                </ul>
            </nav>
            <div className="table-responsive">
                <table className="table align-middle">
                    <thead>
                    <tr>
                        <td>Nom</td>
                        <td>Actions</td>
                    </tr>
                    </thead>
                    <tbody className='table-group-divider'>
                    {
                        displayedGroups.map((item, i) => (
                            <tr key={i}>
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    <NavLink to={`redirections/${item.id}`}>
                                        <i className="bi bi-eye text-primary m-2"></i>
                                    </NavLink>
                                    <i className="bi bi-pencil text-warning m-2" onClick={() => {
                                        setGroupToUp(item);
                                        setUpGroup(true);
                                    }}></i>
                                    <i className="bi bi-trash text-danger m-2"
                                       onClick={() => {
                                           if (window.confirm(`Vous etes sur le point de supprimer le groupe ${item.name} et toutes les redirections qu'il contient ! Cette action est irreversible`)) {
                                               axios.get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?deleteGroup=true&group_delete_id=${item.id}`)
                                                   .then(response => {
                                                       setGroupAdded(true)
                                                   })
                                                   .catch(error => {
                                                       console.error('Error fetching data:', error);
                                                   });
                                           }
                                       }}></i>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
            {
                upGroup ?
                    <form className='upGroupForm' onSubmit={(e) => {
                        e.preventDefault();

                        axios({
                            method: 'post',
                            url: 'http://prod.maplaque-nfc-link.fr/api/appProd.php',
                            data: {
                                group_name: groupNameUp,
                                id: groupToUp.id,
                                updateGroup: 'updateGroup'
                            },
                            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                        })
                            .then(response => {
                                setGroupUp(true);
                                setUpGroup(false);
                            })
                            .catch(error => {
                                console.error('Error fetching data:', error);
                            });

                    }}>
                        <h3>Modifier le groupe : </h3>
                        <div className='form-group'>
                            <label htmlFor="name">Nom du groupe</label>
                            <input className='form-control' type="text" name="name" id="name" onChange={(e) => {
                                setGroupNameUp(e.target.value);
                            }} defaultValue={groupToUp.name}/>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button className='btn btn-success m-3'>Modifier</button>
                            <button className='btn btn-secondary m-3' onClick={(e) => {
                                e.preventDefault();
                                setUpGroup(false)
                            }}>Annuler
                            </button>
                        </div>
                    </form>
                    : null
            }
            {
                addGroup ?
                    <form className='addGroupForm' onSubmit={(e) => {
                        e.preventDefault();

                        axios({
                            method: 'post',
                            url: 'http://prod.maplaque-nfc-link.fr/api/appProd.php',
                            data: {
                                name: groupName,
                                user_id: localStorage.getItem('id'),
                                addRedirectGroup: 'addRedirectGroup'
                            },
                            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                        })
                            .then(response => {
                                setGroupAdded(true);
                                setAddGroup(false);
                            })
                            .catch(error => {
                                console.error('Error fetching data:', error);
                            });

                    }}>
                        <h3>Ajouter un groupe</h3>
                        <div className='form-group'>
                            <label htmlFor="name">Nom du groupe</label>
                            <input className='form-control' type="text" name="name" id="name" onChange={(e) => {
                                setGroupName(e.target.value);
                            }}/>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button className='btn btn-success m-3'>Ajouter</button>
                            <button className='btn btn-secondary m-3' onClick={(e) => {
                                e.preventDefault();
                                setAddGroup(false)
                            }}>Annuler
                            </button>
                        </div>
                    </form>
                    : null
            }
        </div>
    );
};

export default RedirectionsGroups;