import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const AddUser = () => {
    const [userName, setuserName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [showsearch, setShowsearch] = useState('');
    const [success, setSuccess] = useState('');

    const navigate = useNavigate();

    return (
        <div className="login">
            <div className="card">
                <h2>Ajouter un utilisateur</h2>
                {
                    success ? <div class="alert alert-success" role="alert">
                        {success}
                    </div>
                        : null
                }

                <form action="" onSubmit={(e) => {
                    e.preventDefault();

                    axios({
                        method: 'post',
                        url: 'http://prod.maplaque-nfc-link.fr/api/appProd.php',
                        data: {
                            username: userName,
                            password: password,
                            email: email,
                            role: role,
                            showsearch: showsearch,
                            addUser: 'addUser'
                        },
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                    })
                        .then(response => {
                            console.log('Utilisateur ajouté');
                            setuserName('');
                            setPassword('');
                            setEmail('');
                            setRole('');
                            setSuccess('Utilisateur ajouté avec succès !')
                            navigate('/addUser');
                        })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });

                }}>
                    <label htmlFor="name">Nom</label>
                    <input type="text" id='name' name='username' onChange={(e) => setuserName(e.target.value)} />
                    <label htmlFor="mail">Email</label>
                    <input type="mail" id='mail' onChange={(e) => setEmail(e.target.value)} />
                    <label htmlFor="password">Mot de passe</label>
                    <input type="password" id='password' onChange={(e) => setPassword(e.target.value)} />
                    <label htmlFor="role">Role</label>
                    <select className='form-control' name="role" id="role" onChange={(e) => setRole(e.target.value)}>
                        <option disabled selected>-- Séléctionner un role --</option>
                        <option value="admin">Administrateur</option>
                        <option value="vendeur">Vendeur</option>
                    </select>
                    <label htmlFor="showsearch">Accès recherche Google</label>
                    <select className='form-control' name="showsearch" id="showsearch" onChange={(e) => setShowsearch(e.target.value)}>
                        <option disabled selected>-- Séléctionner oui  ou non --</option>
                        <option value="yes">Oui</option>
                        <option value="none">Non</option>
                    </select>
                    <button>Me connecter</button>
                </form>
            </div>
        </div>
    );
};

export default AddUser;