import React, {useEffect, useState} from 'react';
import QrCodesList from '../components/QrCodesList';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import axios from 'axios';
import ReactDOMServer from 'react-dom/server';
import {useNavigate} from 'react-router-dom';
import $ from 'jquery';
import 'select2';

const GenerateQrcode = () => {

    const [name, setName] = useState('')
    const [quantity, setQuantity] = useState('');
    const [group, setGroup] = useState('');
    const [category, setCategory] = useState()

    const [qrCodeList, setQrCodeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [downloaded, setDownloaded] = useState(false);

    const [groups, setGroups] = useState([]);
    const [addGroup, setAddGroup] = useState(false);
    const [groupAdded, setGroupAdded] = useState(false);
    const [groupName, setGroupName] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('role') === 'admin') {
            axios.get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?getAllGroups=true&t=${Date.now()}`)
                .then(response => {
                    setGroups(response.data);
                    setGroupAdded(false);
                  /*  $('select').select2()*/
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } else {
            axios.get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?getGroupsByUser=true&user_id=${localStorage.getItem('id')}&t=${Date.now()}`)
                .then(response => {
                    setGroups(response.data);
                    setGroupAdded(false);
                    /*$('select').select2()*/
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }

    }, [groupAdded]);

    const downloadAllQrCodes = async () => {
        const qrCodeContainer = document.querySelector('.qrcode-list');

        if (!qrCodeContainer) {
            return;
        }

        const qrCodeElements = qrCodeContainer.querySelectorAll('.plaque #qrcode svg');

        if (qrCodeElements.length === 0) {
            return;
        }


        const downloadQrCode = async (index) => {
            if (index >= qrCodeElements.length) {
                setLoading(false);
                setDownloaded(true);
                return;
            }

            const qrCodeElement = qrCodeElements[index];
            const svgString = qrCodeElement.outerHTML; // Get the SVG as a string

            // Create a Blob object from the SVG string
            const blob = new Blob([svgString], {type: 'image/svg+xml;charset=utf-8'});

            // Create a URL for the Blob object
            const url = URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement('a');

            // Set the link's href to the Blob URL
            link.href = url;

            // Set the download attribute to the desired file name
            link.download = name + index + '.svg';

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Remove the link from the body
            document.body.removeChild(link);

            await new Promise(r => setTimeout(r, 1000));

            downloadQrCode(index + 1);
        };

        downloadQrCode(0);

    };

    const handleGeneration = () => {

        let old_url = Date.now();

        let newArray = [...qrCodeList];


        for (let i = 1; i <= quantity; i++) {
            old_url++;

            const url = `https://maplaque-nfc-link.fr/q/${old_url}`;


            const qrCode = <QRCode value={url} fgColor="black" renderAs='svg'/>;
            const qrCodeHTML = (
                <div key={qrCodeList.length + 1} className='plaque' style={{width: '50px', height: '50px'}}>
                    <div id='qrcode' style={{width: '50px', height: '50px'}}>
                        {qrCode}
                    </div>
                </div>
            );


            const qrCodeSVGString = ReactDOMServer.renderToStaticMarkup(qrCode);

            newArray.push(qrCodeHTML);

            const redirectName = name + i;
            const redirectOldUrl = old_url;

            axios({
                method: 'post',
                url: 'http://prod.maplaque-nfc-link.fr/api/appProd.php',
                data: {
                    name: redirectName,
                    old_url: redirectOldUrl,
                    category: category,
                    group_id: group,
                    qrcode: qrCodeSVGString,
                    addRedirect: 'addRedirect'
                },
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            })
                .then(response => {
                    console.log('Redirect ajouté');
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
        setQrCodeList(newArray);
    };

    return (
        <div className="plaques_generation">
            <h1>Generation de Qrcodes</h1>
            {
                error !== '' ? <p style={{color: 'red'}}>{error}</p> : null
            }

            <form onSubmit={(e) => {
                e.preventDefault();
                handleGeneration();
            }}>
                <div className='form-group'>
                    <label htmlFor="name">Nom du groupe de qrcodes</label>
                    <input type="text" name="name" id="name" onChange={(e) => setName(e.target.value)}
                           min="0" placeholder="exemple: g_ ou f_ etc.."/>
                </div>
                <div className='form-group'>
                    <label htmlFor="quantity">Quantité</label>
                    <input type="number" name="quantity" id="quantity" onChange={(e) => setQuantity(e.target.value)}
                           min="0"/>
                </div>
                <div className='form-group'>
                    <label htmlFor="category">Type de lien</label>
                    <select name="category" id="category" onChange={(e) => {
                        setCategory(e.target.value);
                    }}>
                        <option disabled selected>-- Choisissez un type de lien --</option>
                        <option value="google">Google</option>
                        <option value="instagram">Instagram</option>
                        <option value="tripadvisor">TripAdvisor</option>
                        <option value="site">Site</option>
                        <option value="linkedin">Linkedin</option>
                        <option value="doctolib">doctolib</option>
                        <option value="immodvisor">immodvisor</option>
                        <option value="snapchat">snapchat</option>
                        <option value="tiktok">tiktok</option>
                        <option value="trustpilot">trustpilot</option>
                        <option value="facebook">facebook</option>
                        <option value="whatsapp">whatsapp</option>
                        <option value="rdv">rdv</option>
                        <option value="planity">planity</option>
                        <option value="wifi">wifi</option>
                    </select>
                </div>
                <div className='form-group'>
                    <label htmlFor="exampleSelect1">Groupes</label>
                    <select id="exampleSelect1" onChange={(e) => {
                        if (e.target.value === 'add_group') {
                            setAddGroup(true);
                            return;
                        }
                        setGroup(e.target.value);

                    }}>
                        <option disabled selected>--- Choisissez un groupe ---</option>
                        {
                            groups.map((item, i) => (
                                <option key={i} value={item.id}>{item.name}</option>
                            ))
                        }
                        <option value="add_group">-- Ajouter un group --</option>
                    </select>
                </div>
                <button>Générer</button>
            </form>
            {

                qrCodeList.length > 0 ?
                    downloaded ?
                        'Téléchargement terminé'
                        :
                        loading ?
                            <p style={{color: 'green'}}>Chargement en cours de votre PDF, veuillez patientez jusqu'au
                                téléchargement du fichier</p>
                            : <button className='btn btn-warning' onClick={() => {
                                downloadAllQrCodes();
                                setDownloaded(false)
                                setLoading(true);
                            }}>Télécharger tous les qrcodes</button>
                    : null

            }
            < QrCodesList qrCodeList={qrCodeList}/>
            {
                addGroup ?
                    <form className='addGroupForm' onSubmit={(e) => {
                        e.preventDefault();

                        axios({
                            method: 'post',
                            url: 'http://prod.maplaque-nfc-link.fr/api/appProd.php',
                            data: {
                                name: groupName,
                                user_id: localStorage.getItem('id'),
                                addRedirectGroup: 'addRedirectGroup'
                            },
                            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                        })
                            .then(response => {
                                setGroupAdded(true);
                                setAddGroup(false);
                            })
                            .catch(error => {
                                console.error('Error fetching data:', error);
                            });

                    }}>
                        <h3>Ajouter un groupe</h3>
                        <div>
                            <label htmlFor="name">Nom du groupe</label>
                            <input type="text" name="name" id="name" onChange={(e) => {
                                setGroupName(e.target.value);
                            }}/>
                        </div>
                        <button>Ajouter</button>
                        <button onClick={(e) => {
                            e.preventDefault();
                            setAddGroup(false)
                        }}>Annuler
                        </button>
                    </form>
                    : null
            }

        </div>

    );
};

export default GenerateQrcode;