import React, { useEffect, useState } from 'react';
import QrCodesList from '../components/QrCodesList';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import axios from 'axios';
import ReactDOMServer from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'select2';

const GeneratePlaques = () => {

    const [name, setName] = useState('')
    const [startNumber, setStartNumber] = useState('');
    const [endNumber, setEndNumber] = useState('');
    const [group, setGroup] = useState('');
    const [paternCats, setPaterncats] = useState([]);
    const [paternSelected, setPaterndSelected] = useState({});

    const [qrCodeList, setQrCodeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [lastNumber, setLastNumber] = useState(0);

    const [groups, setGroups] = useState([]);
    const [addGroup, setAddGroup] = useState(false);
    const [groupAdded, setGroupAdded] = useState(false);
    const [groupName, setGroupName] = useState('');

    const [paterns, setPaterns] = useState([]);

    const [base64Container, setBase64Container] = useState([]);
    const [executePdfRequest, setExecutePdfRequest] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('role') === 'admin') {
            axios.get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?getAllGroups=true&t=${Date.now()}`)
                .then(response => {
                    setGroups(response.data);
                    setGroupAdded(false);
                     /*$('select').select2();*/
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } else {
            axios.get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?getGroupsByUser=true&user_id=${localStorage.getItem('id')}&t=${Date.now()}`)
                .then(response => {
                    setGroups(response.data);
                    setGroupAdded(false);
                     /*$('select').select2();*/
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }

        axios.get(`http://prod.maplaque-nfc-link.fr/api/app.php?getAllPaterns=true&t=${Date.now()}`)
            .then(response => {
                setPaterns(response.data);
                 /*$('select').select2();*/
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    }, [groupAdded]);

    useEffect(() => {

        const array = [];
        paterns.forEach(element => {
            if (!array.includes(element.category)) {
                array.push(element.category);
            }
        });
        setPaterncats(array);

    }, [paterns]);

    useEffect(() => {
        if (executePdfRequest) {
            axios({
                method: 'post',
                url: 'http://prod.maplaque-nfc-link.fr/api/allacess/TCPDF-main/fpdi2/generatepdfOk5.php',
                data: {
                    arrayBases: base64Container,
                    generePdf: true,
                    width: paternSelected.patern_w,
                    height: paternSelected.patern_h,
                    verso: paternSelected.verso

                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then(response => {
                    window.location.href = 'http://prod.maplaque-nfc-link.fr/pdf_prods/' + response.data.pdfName;
                    setLoading(false);

                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
            setExecutePdfRequest(false);
        }
    }, [executePdfRequest])

    const imgStyle = {
        width: `${paternSelected.patern_w}cm`,
        height: `${paternSelected.patern_h}cm`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    const downloadAllQRsAsPDF = async () => {
        const qrCodeContainer = document.querySelector('.qrcode-list');

        if (!qrCodeContainer) {
            return;
        }

        const qrCodeElements = qrCodeContainer.querySelectorAll('.plaque');

        if (qrCodeElements.length === 0) {
            return;
        }

        const promises = Array.from(qrCodeElements).map(async (element) => {
            const canvas = await html2canvas(element, {
                scale: 3,
                dpi: 300,
                useCORS: true,
            });

            const imgData = canvas.toDataURL('image/jpg');
            return imgData;
        });

        const base64Array = await Promise.all(promises);
        setBase64Container(base64Array);
        setExecutePdfRequest(true);

        setLastNumber(endNumber);
        localStorage.setItem('lastNumber', lastNumber);
    };

    const handleGeneration = () => {
        // const pdfNumber = endNumber - startNumber;

        // if (pdfNumber > 20 && size == '7') {
        //     setError('Vous ne pouvez pas générer plus de 20 plaques en même temps pour les 7x7');
        //     return;
        // } else if (pdfNumber > 10 && size == '12') {
        //     setError('Vous ne pouvez pas générer plus de 10 plaques en même temps pour les 12x12');
        //     return;
        // } else {
        //     setError('');
        // }

        let old_url = Date.now();

        let newArray = [...qrCodeList];
        var start = parseFloat(startNumber);
        var end = parseFloat(endNumber);

        for (let i = start; i <= end; i++) {
            old_url++;

            const url = `https://maplaque-nfc-link.fr/q/${old_url}`;

            const plaqueType = paternSelected.type.toLocaleLowerCase();

            const qrCode = <QRCode value={url} fgColor={paternSelected.qrcode_color} renderAs='svg' />;
            const qrCodeHTML = (
                <div key={qrCodeList.length + 1} className='plaque' style={imgStyle}>
                    <img src={`http://prod.maplaque-nfc-link.fr/api/${paternSelected.image}`} className="plaqueImg" alt="" />
                    <div id='qrcode' style={{
                        position: 'absolute',
                        top: paternSelected.qrcode_top + 'px',
                        left: paternSelected.qrcode_left + 'px',
                        width: paternSelected.qrcode_w + 'cm',
                        height: paternSelected.qrcode_h + 'cm',
                    }}>
                        {qrCode}
                    </div>
                    <p style={{
                        position: 'absolute',
                        top: paternSelected.id_top + 'px',
                        left: paternSelected.id_left + 'px',
                        color: paternSelected.id_color,
                        fontSize: paternSelected.id_width + 'px',
                    }}>#{name}{i}</p>
                    {
                        paternSelected.category.toLowerCase().includes('carte') ?
                            <img src={`img/versos/${plaqueType}-verso.jpg`} className="plaqueVerso" alt="" />
                            : null
                    }
                </div>
            );


            const qrCodeSVGString = ReactDOMServer.renderToStaticMarkup(qrCode);

            newArray.push(qrCodeHTML);

            const redirectName = name + i;
            const redirectOldUrl = old_url;
            const redirectCategory = paternSelected.type;

            setLastNumber(endNumber);
            localStorage.setItem('lastNumber', lastNumber);

            axios({
                method: 'post',
                url: 'http://prod.maplaque-nfc-link.fr/api/appProd.php',
                data: {
                    name: redirectName,
                    old_url: redirectOldUrl,
                    category: redirectCategory,
                    group_id: group,
                    qrcode: qrCodeSVGString,
                    addRedirect: 'addRedirect'
                },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
                .then(response => {
                    console.log('Redirect ajouté');
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
        setQrCodeList(newArray);
    };

    return (
        <div className="plaques_generation">
            <h1>Generation de fichiers pour prods</h1>
            {
                error !== '' ? <p style={{ color: 'red' }}>{error}</p> : null
            }

            <form onSubmit={(e) => {
                e.preventDefault();
                handleGeneration();
            }}>
                <div className='form-group'>
                    <label htmlFor="name">Nom</label>
                    <input type="text" id='name' onChange={(e) => {
                        setName(e.target.value)
                    }} />
                </div>
                <div className='form-group'>
                    {/* <p>Vous pouvez générer à la fois que <strong>maximum 20 plaques pour les 7x7</strong> et <strong>maximum 10 plaques pour les 12x12</strong></p> */}
                    {
                        lastNumber != '' ? <p>Le numéro de votre derniere plaque de type {paternSelected.type} est le : <strong>{lastNumber}</strong></p> : null
                    }
                    <label htmlFor="start">Nombre de départ</label>
                    <input type="number" name="start" id="start" onChange={(e) => setStartNumber(e.target.value)} min="0" />
                </div>
                <div className='form-group'>
                    <label htmlFor="end">Nombre de fin</label>
                    <input type="number" name="end" id="end" onChange={(e) => setEndNumber(e.target.value)} min="0" />
                </div>
                <div className='form-group'>
                    <label htmlFor="type">Paterne</label>
                    <select className="paterns-list" onChange={(e) => {
                        const patern = JSON.parse(e.target.value);
                        setPaterndSelected(patern)
                    }}>
                        <option selected disabled>-- Liste des paternes --</option>
                        {
                            paternCats.map((item, i) => (
                                <optgroup key={i} label={item}>
                                    {
                                        paterns.map((patern, i) => (
                                            patern.category === item ?
                                                <option key={i} value={JSON.stringify(patern)} >{patern.name.split(' ')[1]} ({patern.type})</option>
                                                : null

                                        ))
                                    }
                                </optgroup>
                            ))
                        }
                    </select>
                </div>
                <div className='form-group'>
                    <label htmlFor="exampleSelect1">Groupes</label>
                    <select id="exampleSelect1" onChange={(e) => {
                        if(e.target.value === 'add_group') {
                            setAddGroup(true);
                            return;
                        }
                        setGroup(e.target.value);

                    }}>
                        <option disabled selected>--- Choisissez un groupe ---</option>
                        {
                            groups.map((item, i) => (
                                <option key={i} value={item.id}>{item.name}</option>
                            ))
                        }
                        <option value="add_group">-- Ajouter un group --</option>
                    </select>
                </div>
                <button>Générer</button>
            </form >
            {

                qrCodeList.length > 0 ?
                    loading ? <p style={{ color: 'green' }}>Chargement en cours de votre PDF, veuillez patientez jusqu'au téléchargement du fichier</p>
                        : <button className='btn btn-warning' onClick={() => {
                            downloadAllQRsAsPDF();
                            setLoading(true);
                        }}>Télécharger les fichiers PDF</button>
                    : null

            }
            < QrCodesList qrCodeList={qrCodeList} />
            {
                addGroup ?
                    <form className='addGroupForm' onSubmit={(e) => {
                        e.preventDefault();

                        axios({
                            method: 'post',
                            url: 'http://prod.maplaque-nfc-link.fr/api/appProd.php',
                            data: {
                                name: groupName,
                                user_id: localStorage.getItem('id'),
                                addRedirectGroup: 'addRedirectGroup'
                            },
                            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                        })
                            .then(response => {
                                setGroupAdded(true);
                                setAddGroup(false);
                            })
                            .catch(error => {
                                console.error('Error fetching data:', error);
                            });

                    }}>
                        <h3>Ajouter un groupe</h3>
                        <div>
                            <label htmlFor="name">Nom du groupe</label>
                            <input type="text" name="name" id="name" onChange={(e) => {
                                setGroupName(e.target.value);
                            }} />
                        </div>
                        <button>Ajouter</button>
                        <button onClick={(e) => {
                            e.preventDefault();
                            setAddGroup(false)
                        }}>Annuler</button>
                    </form >
                    : null
            }

        </div >

    );
};

export default GeneratePlaques;