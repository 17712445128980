import React from 'react';
import QrCode from './QrCode';

const QrCodesList = ({ qrCodeList }) => {

    return (
        <>
            {
                qrCodeList.length > 0 ?
                    <div className='qrcode-container'>
                        <h2>Liste des QrCode générés</h2>
                        <div className='qrcode-list'>
                            <QrCode qrCodeList={qrCodeList} />
                        </div>
                    </div>
                    : null
            }

        </>
    );
};

export default QrCodesList;