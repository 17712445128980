import React, { useEffect, useState } from 'react';
import axios from 'axios';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom';

const SetPatern = ({ setPaternAdded }) => {
    const [patern, setPatern] = useState([]);
    const [paternOk, setPaternOk] = useState(false);

    const [qrcodeTop, setQrcodeTop] = useState(0);
    const [qrcodeLeft, setQrcodeLeft] = useState(0);
    const [qrcodeWidth, setQrcodeWidth] = useState(2.5);
    const [qrcodeColor, setQrcodeColor] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [mouseOffset, setMouseOffset] = useState({ x: 0, y: 0 });

    const [idTop, setIdTop] = useState(100);
    const [idLeft, setIdLeft] = useState(100);
    const [idWidth, setIdWidth] = useState(14);
    const [idColor, setIdColor] = useState('');
    const [isDraggingId, setIsDraggingId] = useState(false);
    const [mouseOffsetId, setMouseOffsetId] = useState({ x: 0, y: 0 });


    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`http://prod.maplaque-nfc-link.fr/api/app.php?getLastInsertPatern=true&t=${Date.now()}`)
            .then(response => {
                setPatern(response.data[0]);
                setPaternOk(true);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setMouseOffset({
            x: e.clientX - qrcodeLeft,
            y: e.clientY - qrcodeTop,
        });
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const newLeft = e.clientX - mouseOffset.x;
            const newTop = e.clientY - mouseOffset.y;
            setQrcodeLeft(newLeft);
            setQrcodeTop(newTop);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleWidthChange = (e) => {
        const newWidth = parseFloat(e.target.value);
        if (!isNaN(newWidth)) {
            setQrcodeWidth(newWidth);
        }
    };


    const handleMouseDownId = (e) => {
        setIsDraggingId(true);
        setMouseOffsetId({
            x: e.clientX - idLeft,
            y: e.clientY - idTop,
        });
    };

    const handleMouseMoveId = (e) => {
        if (isDraggingId) {
            const newLeft = e.clientX - mouseOffsetId.x;
            const newTop = e.clientY - mouseOffsetId.y;
            setIdLeft(newLeft);
            setIdTop(newTop);
        }
    };

    const handleMouseUpId = () => {
        setIsDraggingId(false);
    };
    const validePatern = () => {
        axios({
            method: 'post',
            url: 'http://prod.maplaque-nfc-link.fr/api/app.php',
            data: {
                patern_id: patern.id,
                qrcode_w: qrcodeWidth,
                qrcode_h: qrcodeWidth,
                qrcode_color: qrcodeColor,
                qrcode_top: qrcodeTop,
                qrcode_left: qrcodeLeft,
                id_top: idTop,
                id_left: idLeft,
                id_width: idWidth,
                id_color: idColor,
                validePatern: 'validePatern'
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then(response => {
                setPaternAdded(false);
                navigate('/plaques');
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    return (
        <div>
            {
                paternOk ? (
                    <>
                        <h2>Entreprise : {patern.entreprise.replaceAll('-', ' ')}</h2>
                        <h3>Catégorie : {patern.category.replaceAll('-', ' ')}</h3>
                        <h4>Type de lien : {patern.type.replaceAll('-', ' ')}</h4>
                        <p>Positionner le Qrcode ainsi que sont identifiant (#1)</p>
                        <div
                            style={{
                                height: patern.patern_h + 'cm',
                                width: patern.patern_w + 'cm',
                                position: 'relative',

                            }}
                            className="patern"
                        >
                            <img
                                src={`http://prod.maplaque-nfc-link.fr/api/${patern.image}`}
                                alt=""
                                style={{
                                    width: '100%',
                                    pointerEvents: 'none'
                                }}
                            />
                            <QRCode
                                style={{
                                    position: 'absolute',
                                    top: qrcodeTop,
                                    left: qrcodeLeft,
                                    width: qrcodeWidth + 'cm',
                                    height: qrcodeWidth + 'cm',
                                    cursor: isDragging ? 'grabbing' : 'grab',

                                }}
                                className='qrcode-patern'
                                value="https://maplaque-nfc.fr"
                                fgColor={qrcodeColor}
                                renderAs="svg"
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                            />
                            <div style={{
                                position: 'absolute',
                                top: idTop,
                                left: idLeft,
                                color: idColor,
                                fontSize: idWidth + 'px',
                                cursor: isDraggingId ? 'grabbing' : 'grab',
                                userSelect: 'none'
                            }}
                                onMouseDown={handleMouseDownId}
                                onMouseMove={handleMouseMoveId}
                                onMouseUp={handleMouseUpId}
                            >
                                <p>#1</p>
                            </div>
                        </div>
                        <div style={{ marginTop: '20px !important' }}>
                            <label>Taille du QRCode (cm): </label>
                            <input type="number" value={qrcodeWidth} onChange={handleWidthChange} />
                        </div>
                        <div>
                            <label>Couleur du QrCode : </label>
                            <input type="color" onChange={(e) => setQrcodeColor(e.target.value)} />
                        </div>
                        <div>
                            <label>taile de l'identifiant (#1) : </label>
                            <input type="number" value={idWidth} onChange={(e) => setIdWidth(e.target.value)} />
                        </div>
                        <div>
                            <label>Couleur de l'identifiant (#1) : </label>
                            <input type="color" onChange={(e) => setIdColor(e.target.value)} />
                        </div>
                        <p style={{ padding: '10px 20px', backgroundColor: '#bb438e', color: 'white', width: 'max-content', borderRadius: '20px' }} onClick={() => validePatern()}>Valider le paterne</p>
                    </>
                ) : null
            }
        </div>
    );
};

export default SetPatern;
