import React, {useState} from 'react';

const ExportCSVMultiple = ({redirections}) => {

    const [categoriesList, setCategoriesList] = useState([]);

    const exportToTXT = () => {
        const categories = {};

        redirections.forEach(redirection => {
            if (!categories[redirection.category]) {
                categories[redirection.category] = [];
            }
            categories[redirection.category].push(`https://maplaque-nfc-link.fr/q/${redirection.old_url}`);
        });

        setCategoriesList(categories);

        let header = Object.keys(categories);
        // Préfixer chaque catégorie avec "#"
        header = header.map(category => "#" + category);

        const csvRows = [];
        const maxRows = Math.max(...Object.values(categories).map(arr => arr.length));

        for (let i = 0; i < maxRows; i++) {
            const row = [];
            for (let j = 0; j < header.length; j++) {
                row.push(categories[header[j].substring(1)][i] || ''); // Enlever le "#" avant de chercher dans l'objet categories
            }
            csvRows.push(row.join(' '));
        }

        csvRows.unshift(header.join(' '));
        const csvData = new Blob([csvRows.join('\n')], {type: 'text/csv;charset=utf-8;'});
        const csvUrl = URL.createObjectURL(csvData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';

        switch (Object.keys(categoriesList).length) {
            case 2:
                hiddenElement.download = 'txt_duo.txt';
                break;
            case 3:
                hiddenElement.download = 'txt_trio.txt';
                break;
            default:
                hiddenElement.download = 'txt_solo.txt';
                break;
        }
        hiddenElement.click();
    };


    return (
        <p className='btn btn-warning' onClick={() => exportToTXT()}>Exportez en TXT (duo/trio)</p>
    )
};

export default ExportCSVMultiple;