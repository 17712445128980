import React, {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

const Layout = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    useEffect(() => {
        axios
            .get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?checkToken=checkToken&id=${id}&token=${token}&t=${Date.now()}`)
            .then((response) => {
                if (!response.data) {
                    navigate('/');
                } else {
                    if (location.pathname === '/') {
                        navigate('/groupes');
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [location.pathname]);

    return (
        <>
            <header>
                <NavLink to='/groupes'>
                    <img src='https://maplaque-nfc.fr/wp-content/uploads/2022/07/logo-web-blanc.svg'
                         alt='Logo MaPlaqueNFC'/>
                </NavLink>
                <ul>
                    {
                        localStorage.getItem('role') === 'admin' ? (
                            <li>
                                <NavLink to='/allPatern'>Listes des paternes</NavLink>
                            </li>
                        ) : null
                    }
                    {
                        localStorage.getItem('role') === 'admin' ? (
                            <li>
                                <NavLink to='/patern'>Ajouter un paterne</NavLink>
                            </li>
                        ) : null
                    }

                    {
                        localStorage.getItem('role') === 'admin' ? (
                            <li>
                                <NavLink to='/plaques'>Générateur de fichiers pour prod</NavLink>
                            </li>
                        ) : null
                    }
                    <li>
                        <NavLink to='/qrcodes'>Générateur de qrcodes</NavLink>
                    </li>
                    <li>
                        <NavLink to='/groupes'>Redirections par groupes</NavLink>
                    </li>
                    {
                        localStorage.getItem('role') === 'admin' ? (
                            <li>
                                <NavLink to='/addUser'>Ajouter un utilisateur</NavLink>
                            </li>
                        ) : null
                    }
                    <li
                        onClick={() => {
                            localStorage.removeItem('token');
                            localStorage.removeItem('name');
                            localStorage.removeItem('id');
                            localStorage.removeItem('role');
                            navigate('/');
                        }}
                    >
                        Déconnexion
                    </li>
                </ul>
            </header>
            <Outlet/>
        </>
    );
};

export default Layout;