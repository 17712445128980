import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AddPaternForm = ({ paternAdded, setPaternAdded }) => {
    const [category, setCategory] = useState('');
    const [type, setType] = useState('');
    const [entreprise, setEntreprise] = useState('');
    const [image, setImage] = useState([]);
    const [imageVerso, setImageVerso] = useState([]);
    const [message, setMessage] = useState('');

    const [paterns, setPaterns] = useState([]);
    const [paternCats, setPaterncats] = useState([]);

    useEffect(() => {
        axios.get(`http://prod.maplaque-nfc-link.fr/api/app.php?getAllPaterns=true&t=${Date.now()}`)
            .then(response => {
                setPaterns(response.data);

                setTimeout(() => {
                    setMessage('');
                }, 2000)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [paternAdded])

    useEffect(() => {

        const array = [];
        paterns.forEach(element => {
            if (!array.includes(element.category)) {
                array.push(element.category);
            }
        });
        setPaterncats(array);

    }, [paterns])
    return (
        <section className="addPatern">
            <h1>Ajouter un paterne</h1>
            <div className="allPaterns">
                <p>Liste des paternes déjà enregistrés</p>
                <select className="paterns-list">
                    <option selected disabled>-- Liste des paternes --</option>
                    {
                        paternCats.map((item, i) => (
                            <optgroup key={i} label={item}>
                                {
                                    paterns.map((patern, i) => (
                                        patern.category === item ?
                                            <option key={i} disabled>{patern.name.split(' ')[1]} ({patern.type})</option>
                                            : null

                                    ))
                                }
                            </optgroup>
                        ))
                    }
                </select>
            </div>
            {
                message ? <p className='addPaternMessage'>{message}</p> : null
            }
            <form onSubmit={async (e) => {
                e.preventDefault();
                const entrepriseReplaced = entreprise.replace(' ', '-');
                setEntreprise(entrepriseReplaced)

                const paternVerify = () => {
                    for (const item of paterns) {
                        if (item.category === category && item.entreprise === entrepriseReplaced && item.type === type) {
                            return false;
                        }
                    }
                    return true;
                }

                if (paternVerify() === true) {
                    axios({
                        method: 'post',
                        url: 'http://prod.maplaque-nfc-link.fr/api/app.php',
                        data: {
                            category: category,
                            type: type,
                            entreprise: entreprise,
                            image: image,
                            verso: imageVerso,
                            addImgPatern: 'addImgPatern'
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((response) => {
                        setMessage(response.data);
                        setPaternAdded(true);
                    })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });
                } else {
                    setMessage('Le paterne pour se produit, cette entreprise et se type de lien a déjà été ajouté !')
                }
            }}>
                <div>
                    <label htmlFor="category">Catégorie du produit</label>
                    <select name="category" id="category" onChange={(e) => setCategory(e.target.value)} required>
                        <option disabled selected>-- Séléctionner une catégorie --</option>
                        <option value="plaque-12">Plaque 12x12</option>
                        <option value="plaque-7">Plaque 7x7</option>
                        <option value="plaque-9">Plaque 9x9</option>
                        <option value="plaque-pro">Plaque Pro</option>
                        <option value="carte">Carte</option>
                        <option value="sticker">Sticker</option>
                        <option value="portecle">Porte clé</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="type">Type de lien</label>
                    <select name="type" id="type" onChange={(e) => setType(e.target.value)} required>
                        <option disabled selected>-- Séléctionner un type --</option>
                        <option value="google">Google</option>
                        <option value="instagram">Instagram</option>
                        <option value="tripadvisor">TripAdvisor</option>
                        <option value="site">Site</option>
                        <option value="linkedin">Linkedin</option>
                        <option value="doctolib">doctolib</option>
                        <option value="immodvisor">immodvisor</option>
                        <option value="snapchat">snapchat</option>
                        <option value="tiktok">tiktok</option>
                        <option value="trustpilot">trustpilot</option>
                        <option value="facebook">facebook</option>
                        <option value="whatsapp">whatsapp</option>
                        <option value="rdv">rdv</option>
                        <option value="planity">planity</option>
                        <option value="wifi">wifi</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="entreprise">Nom de l'entreprise</label>
                    <input type="text" name="entreprise" id="entreprise" onChange={(e) => setEntreprise(e.target.value)} required />
                </div>
                <div>
                    <label htmlFor="image">Image</label>
                    <input type="file" name="image" id="image" onChange={(e) => {
                        setImage([...e.target.files]);
                    }} required /> z
                </div>
                {
                    category === 'carte' || category === 'portecle' ?
                        <div>
                            <label htmlFor="verso">Verso (obligatoire si carte)</label>
                            <input type="file" name="verso" id="verso" onChange={(e) => {
                                setImageVerso([...e.target.files]);
                            }} required />
                        </div>
                        : null
                }
                <button>Ajouter</button>
            </form>
        </section>
    );
};

export default AddPaternForm;