import React, { useState } from 'react';
import AddPaternForm from '../components/AddPaternForm';
import SetPatern from '../components/SetPatern';

const AddPatern = () => {
    const [paternAdded, setPaternAdded] = useState(false);


    return (
        <>
            {
                !paternAdded ?
                    <AddPaternForm paternAdded={paternAdded} setPaternAdded={setPaternAdded} />
                    :
                    <SetPatern paternAdded={paternAdded} setPaternAdded={setPaternAdded}/>
            }
        </>
    );
};

export default AddPatern;