import React from 'react';

const ExportQrCodesCsv = ({redirections}) => {

    const exportToCsv = () => {
        console.log(redirections);
        const header = ['#QR'];
        const csv = redirections.map(redirection => {
            const url = `https://maplaque-nfc-link.fr/q/${redirection.old_url}`;
            /*const vCard = `BEGIN:VCARD\\NVERSION:2.1\\nURL:${url}\\nEND:VCARD`;*/
            return [url];
        }).map(row => row.join(','));
        csv.unshift(header.join(','));
        const csvData = new Blob([csv.join('\n')], {type: 'text/csv;charset=utf-8;'});
        const csvUrl = URL.createObjectURL(csvData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'qr_codes.csv';
        hiddenElement.click();
    };

        return (
            <p className='btn btn-danger' onClick={() => exportToCsv()}>Exportez en CSV (beaunegravure)</p>
        );
    }
;

export default ExportQrCodesCsv;