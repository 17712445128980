import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const [userName, setuserName] = useState('');
    const [password, setPassword] = useState('');

    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    useEffect(() => {
        localStorage.removeItem('token');
        axios.get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?checkToken=checkToken&id=${id}&token=${token}&t=${Date.now()}`)
            .then(response => {
                if (!response.data) {
                    navigate('/');
                    return
                } else {
                    navigate('/groupes');
                    return
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [])

    return (

        <div className="login">
            <div className="card">
                <img style={{ width: '100px', margin: 'auto' }} src="https://maplaque-nfc.fr/q/LOGO-CARRE.svg" alt="Logo maplaque" />
                <h2>Connexion</h2>
                {
                    error !== '' ?
                        <p style={{ color: 'red' }}>{error}</p>
                        : null
                }
                <form action="" onSubmit={(e) => {
                    e.preventDefault();

                    axios.get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?login=login&username=${userName}&password=${password}`)
                        .then(response => {
                            if (response.data === 'Nom d\'utilisateur ou mot de passe incorrect.') {
                                setError('Les identifiants sont incorrects !')
                            } else {
                                const token = response.data.login_token;
                                localStorage.setItem('token', token);
                                localStorage.setItem('name', response.data.username);
                                localStorage.setItem('id', response.data.id);
                                localStorage.setItem('role', response.data.role);
                                navigate('/groupes');
                            }
                        })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });

                }}>
                    <label htmlFor="name">Nom</label>
                    <input type="text" id='name' name='username' onChange={(e) => setuserName(e.target.value)} />
                    <label htmlFor="password">Mot de passe</label>
                    <input type="password" id='password' onChange={(e) => setPassword(e.target.value)} />
                    <button>Me connecter</button>
                </form>
            </div>
        </div>
    );
};

export default Login;