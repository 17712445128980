import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Dropdown } from 'react-bootstrap';
import Papa from 'papaparse';
import ExportQrCodesCsv from "../components/ExportQrCodesCSV";
import ExportCSVMultiple from "../components/ExportCSVMultiple";

const RedirectionList = () => {
    const { id } = useParams();
    const [redirections, setRedirections] = useState([]);
    const [addRedirection, setAddRedirection] = useState(false);
    const [redirectionAdded, setRedirectionAdded] = useState(false)
    const [groups, setGroups] = useState([]);

    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [url, setUrl] = useState('');
    const old_url = Date.now();
    const groupId = id;

    const [updateRedirection, setUpdateRedirection] = useState(false);
    const [redirectionToUp, setRedirectionToUp] = useState([]);
    const [nameToUp, setNameToUp] = useState('');
    const [typeToUp, setTypeToUp] = useState('');
    const [urlToUp, setUrlToUp] = useState('');
    const [groupToUp, setGroupToUp] = useState('')

    const [loader, setLoader] = useState(true);

    const [showId, setShowId] = useState(true);
    const [showName, setShowName] = useState(true);
    const [showType, setShowType] = useState(true);
    const [showUrl, setShowUrl] = useState(true);
    const [showQrCode, setShowQrCode] = useState(true);
    const [showQrCodeCompteur, setShowQrCodeCompteur] = useState(true);
    const [showCopyQrCode, setShowCopyQrCode] = useState(true);
    const [showAction, setShowAction] = useState(true);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedRedirections = redirections.slice(startIndex, endIndex);

    const qrCodeRef = useRef();

    const [copiedItemIndex, setCopiedItemIndex] = useState(null);

    const handleDownload = (name, svgContent) => {
        // const svgContent = qrCodeRef.current.querySelector('svg').outerHTML;

        const blob = new Blob([svgContent], { type: 'image/svg+xml' });

        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}.svg`;
        a.click();

        URL.revokeObjectURL(url);
    };

    // const handleDownload = (name) => {
    //     const svgContent = qrCodeRef.current.querySelector('svg');

    //     htmlToImage.toJpeg(svgContent, { quality: 0.95 })
    //         .then(function (dataUrl) {
    //             const a = document.createElement('a');
    //             a.href = dataUrl;
    //             a.download = `qrcode_${name}.jpg`;
    //             a.click();
    //         });
    // };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(redirections.length / itemsPerPage);

    const paginationButtons = [];

    for (let i = 1; i <= totalPages; i++) {
        if (i === 1 || i === totalPages || i === currentPage || i === currentPage - 1 || i === currentPage + 1) {
            paginationButtons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={i === currentPage ? "page-item active" : "page-item"}
                >
                    {i}
                </button>
            );
        }
        // Add ellipsis for skipped pages but avoid duplicates
        else if (i === currentPage - 2 || i === currentPage + 2) {
            paginationButtons.push(
                <button key={i} className="page-item disabled" disabled>
                    ...
                </button>
            );
        }
    }

    useEffect(() => {
        axios.get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?getRedirectionsByGroupId=true&group_id=${id}&t=${Date.now()}`)
            .then(response => {
                setRedirections(response.data);
                setRedirectionAdded(false);
                setLoader(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [redirectionAdded, updateRedirection])

    useEffect(() => {
        axios.get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?getGroupsByUser=true&user_id=${localStorage.getItem('id')}&t=${Date.now()}`)
            .then(response => {
                setGroups(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [])

    const handleCopy = (index) => {
        setCopiedItemIndex(index);
        setTimeout(() => {
            setCopiedItemIndex(null);
        }, 1500);
    };

    const downloadAllQRsAsPDF = async () => {

        const seeAllRedirections = async () => {
            setItemsPerPage(redirections.length);
        }

        const generatePDF = async () => {
            await seeAllRedirections();
            const redirectionsList = document.querySelector('#redirect_table');
            const height = redirectionsList.offsetHeight;
            const width = redirectionsList.offsetWidth;
            console.log(width);
            console.log(height);
            // return

            if (!redirectionsList) {
                return;
            }

            const pdf = new jsPDF('a4', 'px', [width, height]);
            console.log(pdf);
            const canvas = await html2canvas(redirectionsList, {
                scale: 3,
                useCORS: true,
            });

            const imgData = canvas.toDataURL('image/svg');

            pdf.addImage(imgData, 'SVG', 0, 0, width, height);
            canvas.remove();

            pdf.save('all_qrcodes.pdf');
            setItemsPerPage(10);
        }
        generatePDF();
    };

    const exportToCSV = (data) => {
        if (data.length === 0) {
            alert('Aucune donnée à exporter.');
            return;
        }

        const csvData = Papa.unparse(data);

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');

        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'exported_data.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            alert('Le téléchargement CSV n\'est pas pris en charge par votre navigateur. Veuillez copier les données manuellement.');
        }
    };


    return (
        <div className='groups-container'>
            <h1 className='text-center p-2 display-5'>Listes des redirections</h1>
            <div className="row">
                <div className='d-block mx-auto'>
                    <p className='text-center btn btn-secondary d-block col-6 mx-auto' onClick={() => {
                        setAddRedirection(true);
                    }}>Ajouter une redirection</p>
                </div>
            </div>
            <div className="row m-3">
                <div className='col-12 col-md-6 mx-auto'>
                    <input type="text" className='form-control' placeholder='Rechercher ...' onChange={(e) => {
                        const redirectionsFiltered = redirections.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()) || item.new_url.toLowerCase().includes(e.target.value.toLowerCase()) || item.id.toLowerCase().includes(e.target.value.toLowerCase()) || item.category.toLowerCase().includes(e.target.value.toLowerCase()) || item.old_url.toLowerCase().includes(e.target.value.toLowerCase()) || item.new_url.toLowerCase().includes(e.target.value.toLowerCase()));
                        setRedirections(redirectionsFiltered);
                        setCurrentPage(1);

                        if (e.target.value === '') {
                            setRedirectionAdded(true);
                        }
                    }} />
                </div>
            </div>
            <div className="export col-12 mx-auto d-flex justify-content-center">
                <p className='btn btn-primary' onClick={() => downloadAllQRsAsPDF()}>Export en PDF</p>
                <p className='btn btn-primary' onClick={() => exportToCSV(redirections)}>Export en CSV</p>
                <ExportQrCodesCsv redirections={redirections} />
                <ExportCSVMultiple redirections={redirections} />
                <Dropdown>
                    <Dropdown.Toggle variant="white" id="dropdown-basic">
                        -- Colonnes --
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {
                            if (showId) {
                                setShowId(false)
                            } else {
                                setShowId(true)
                            }
                        }} className={showId ? 'bg-secondary text-white' : 'bg-white'}>Id</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            if (showType) {
                                setShowType(false)
                            } else {
                                setShowType(true)
                            }
                        }} className={showType ? 'bg-secondary text-white' : 'bg-white'}>Type</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            if (showName) {
                                setShowName(false)
                            } else {
                                setShowName(true)
                            }
                        }} className={showName ? 'bg-secondary text-white' : 'bg-white'}>Nom</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            if (showUrl) {
                                setShowUrl(false)
                            } else {
                                setShowUrl(true)
                            }
                        }} className={showUrl ? 'bg-secondary text-white' : 'bg-white'}>Url</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            if (showQrCode) {
                                setShowQrCode(false)
                            } else {
                                setShowQrCode(true)
                            }
                        }} className={showQrCode ? 'bg-secondary text-white' : 'bg-white'}>QrCode</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            if (showQrCodeCompteur) {
                                setShowQrCodeCompteur(false)
                            } else {
                                setShowQrCodeCompteur(true)
                            }
                        }} className={showQrCodeCompteur ? 'bg-secondary text-white' : 'bg-white'}>Compteur scan qrCode</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            if (showCopyQrCode) {
                                setShowCopyQrCode(false)
                            } else {
                                setShowCopyQrCode(true)
                            }
                        }} className={showCopyQrCode ? 'bg-secondary text-white' : 'bg-white'}>Copie SVG</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            if (showAction) {
                                setShowAction(false)
                            } else {
                                setShowAction(true)
                            }
                        }} className={showAction ? 'bg-secondary text-white' : 'bg-white'}>Action</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    &laquo;
                </button>
                <div className='page-item-list'>
                    {paginationButtons}
                </div>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    &raquo;
                </button>
            </div>
            <div className="page-indicator text-center">
                <p className='m-2'>Page {currentPage} sur {totalPages}</p>
            </div>
            <div className="table-responsive">
                {
                    loader ?
                        <div className="loading-form m-5 d-flex justify-content-center">
                            <div className="spinner-grow text-primary m-1" role="status">
                                <span className="sr-only"></span>
                            </div>
                            <div className="spinner-grow text-secondary m-1" role="status">
                                <span className="sr-only"></span>
                            </div>
                            <div className="spinner-grow text-success m-1" role="status">
                                <span className="sr-only"></span>
                            </div>
                            <div className="spinner-grow text-danger m-1" role="status">
                                <span className="sr-only"></span>
                            </div>
                            <div className="spinner-grow text-warning m-1" role="status">
                                <span className="sr-only"></span>
                            </div>
                            <div className="spinner-grow text-info m-1" role="status">
                                <span className="sr-only"></span>
                            </div>
                            <div className="spinner-grow text-dark m-1" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                        : null
                }

                <table className="table align-middle" id='redirect_table'>
                    <thead>
                        <tr>
                            {
                                showId ? <td>#</td> : null
                            }
                            {
                                showType ? <td>Plaques</td> : null
                            }
                            {
                                showName ? <td>Nom</td> : null
                            }
                            {
                                showUrl ? <td>Url</td> : null
                            }
                            {
                                showUrl ? <td>Url de redirection</td> : null
                            }
                            {
                                showQrCode ? <td>QrCode</td> : null
                            }
                            {
                                showQrCodeCompteur ? <td>Compteur scan</td> : null
                            }
                            {
                                showCopyQrCode ? <td></td> : null
                            }
                            {
                                showCopyQrCode ? <td>Actions</td> : null
                            }
                        </tr>
                    </thead>
                    <tbody className='table-group-divider' id='redirections_list'>
                        {
                            redirections.length == 0 ?
                                <tr>
                                    <td>Aucune redirections pour le moment ! Cliquez sur le bouton ci-dessus pour en ajouter une.</td>
                                </tr>
                                : null
                        }
                        {
                            displayedRedirections.map((item, i) => (
                                <tr key={i}>
                                    {
                                        showId ? <td>{item.id}</td> : null
                                    }
                                    {
                                        showType ? <td>{item.category}</td> : null
                                    }
                                    {
                                        showName ? <td>{item.name}</td> : null
                                    }
                                    {
                                        showUrl ?
                                            <td>{
                                                item.new_url === 'https://maplaque-nfc-link.fr/' || item.new_url === 'https://maplaque-nfc.fr'
                                                    ?
                                                    <img src="/logo.svg" style={{ width: '25px', height: '25px' }} alt="Logo MaPlaqueNFC"></img>
                                                    : item.new_url
                                            }</td>
                                            : null
                                    }
                                    {
                                        showUrl ?
                                            <td>{item.old_url}</td>
                                            : null
                                    }
                                    {
                                        showQrCode ?
                                            <td >
                                                {
                                                    item.qrcode ?
                                                        item.qrcode.startsWith("<svg") ?
                                                            // <a
                                                            //     className='text-center mx-auto d-block'
                                                            //     href={`data:image/svg+xml;base64,${btoa(item.qrcode)}`}
                                                            //     download={`qrcode_${item.name}`}
                                                            // >
                                                            <>
                                                                <p data-bs-toggle="modal" data-bs-target={`#qrcode_${item.id}`} className='qrCode-container' dangerouslySetInnerHTML={{ __html: item.qrcode }}></p>
                                                                <p onClick={() => handleDownload(item.name, item.qrcode)} ref={qrCodeRef}><i className="bi bi-file-earmark-arrow-down"></i> fichier svg</p>
                                                            </>
                                                            //     {/*
                                                            // </a> */}
                                                            :
                                                            <img style={{ width: '80px', height: '80px' }} src={`https://maplaque-nfc-link.fr/url/admin/${item.qrcode}`} alt="" />
                                                        : <img style={{ width: '80px', height: '80px' }} src="/img/noqrcode.jpg" alt="" />

                                                }
                                                <div className="modal fade" id={`qrcode_${item.id}`}>
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            {
                                                                item.qrcode ?
                                                                    item.qrcode.startsWith("<svg") ?
                                                                        // <a
                                                                        //     className='text-center mx-auto d-block'
                                                                        //     href={`data:image/svg+xml;base64,${btoa(item.qrcode)}`}
                                                                        //     download={`qrcode_${item.name}`}
                                                                        // >

                                                                        <p ref={qrCodeRef} className='qrCode-container' dangerouslySetInnerHTML={{ __html: item.qrcode }}></p>
                                                                        // <i className="bi bi-file-earmark-arrow-down"></i>
                                                                        // </a>
                                                                        :
                                                                        <img style={{ width: '250px', height: '250px' }} src={`https://maplaque-nfc-link.fr/url/admin/${item.qrcode}`} alt="" />
                                                                    : <img style={{ width: '250px', height: '250px' }} src="/img/noqrcode.jpg" alt="" />

                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            : null
                                    }
                                    {
                                        showQrCodeCompteur ? <td>{item.compteur} fois <br />
                                            <p className='btn btn-primary' onClick={() => {
                                                if (window.confirm(`Vous etes sur le point de remettre à zéro le compteur la redirection ${item.name} ! Cette action est irreversible`)) {
                                                    axios.get(`http://prod.maplaque-nfc-link.fr/api/app.php?resetQrcodeCompteur=true&id=${item.id}`)
                                                        .then(response => {
                                                            setRedirectionAdded(true)
                                                        })
                                                        .catch(error => {
                                                            console.error('Error fetching data:', error);
                                                        });
                                                }
                                            }}>remettre à zéro</p>
                                        </td> : null
                                    }
                                    {
                                        showCopyQrCode ?
                                            item.qrcode ?
                                                item.qrcode.startsWith("<svg") ?
                                                    <td>
                                                        <CopyToClipboard
                                                            text={item.qrcode}
                                                            onCopy={() => handleCopy(i)}
                                                        >
                                                            <div style={{ cursor: 'pointer' }}>
                                                                {copiedItemIndex === i
                                                                    ? 'Code SVG Copié'
                                                                    : 'Copier Code SVG'}
                                                            </div>
                                                        </CopyToClipboard>
                                                    </td>
                                                    : null
                                                : null
                                            : null
                                    }
                                    {
                                        showAction ?
                                            <td>
                                                <i className="bi bi-pencil text-warning m-2" onClick={() => {
                                                    setRedirectionToUp(item);
                                                    setUpdateRedirection(true);
                                                    setNameToUp(item.name);
                                                    setTypeToUp(item.category);
                                                    setUrlToUp(item.new_url);
                                                    setGroupToUp(item.group_id);
                                                }}></i>
                                                <i className="bi bi-trash text-danger m-2" onClick={() => {
                                                    if (window.confirm(`Vous etes sur le point de supprimer la redirection ${item.name} ! Cette action est irreversible`)) {
                                                        axios.get(`http://prod.maplaque-nfc-link.fr/api/appProd.php?deleteRedirection=true&redirection_delete_id=${item.id}`)
                                                            .then(response => {
                                                                setRedirectionAdded(true)
                                                            })
                                                            .catch(error => {
                                                                console.error('Error fetching data:', error);
                                                            });
                                                    }
                                                }}></i>
                                            </td>
                                            : null
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            {
                addRedirection ?
                    <form className='addGroupForm' onSubmit={(e) => {
                        e.preventDefault();

                        axios({
                            method: 'post',
                            url: 'http://prod.maplaque-nfc-link.fr/api/appProd.php',
                            data: {
                                name: name,
                                old_url: old_url,
                                category: type,
                                group_id: groupId,
                                new_url: url,
                                addRedirectToGroup: 'addRedirectToGroup'
                            },
                            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                        })
                            .then(response => {
                                setRedirectionAdded(true);
                                setAddRedirection(false);
                            })
                            .catch(error => {
                                console.error('Error fetching data:', error);
                            });

                    }}>
                        <h3>Ajouter une redirection</h3>
                        <div className='form-group'>
                            <label htmlFor="name">Nom</label>
                            <input className='form-control' type="text" name="name" id="name" onChange={(e) => {
                                setName(e.target.value);
                            }} />
                        </div>
                        <div className='form-group'>
                            <label htmlFor="type">Type</label>
                            <select className='form-control' name="type" id="type" onChange={(e) => {
                                setType(e.target.value);
                            }}>
                                <option disabled selected>-- Séléctionnez un type --</option>
                                <option value="Perso">Plaque</option>
                                <option value="Perso">Perso</option>
                                <option value="Google">Google</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Snapchat">Snapchat</option>
                                <option value="TikTok">TikTok</option>
                                <option value="WhatsApp">WhatsApp</option>
                                <option value="Linkedin">Linkedin</option>
                                <option value="Youtube">Youtube</option>
                                <option value="Site">Site internet</option>
                                <option value="Immodvisor">Immodvisor</option>
                                <option value="Trustpilot">Trustpilot</option>
                                <option value="RDV">Prise de RDV</option>
                                <option value="Wifi">Wifi</option>
                                <option value="Doctolib">Doctolib</option>
                                <option value="Tripadvisor">Tripadvisor</option>
                                <option value="Planity">Planity</option>
                                <option value="Duo">Duo</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <label htmlFor="url">Url de redirection</label>
                            <input className='form-control' type="url" name="url" id="url" onChange={(e) => {
                                setUrl(e.target.value);
                            }} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button className='btn btn-success m-3'>Ajouter</button>
                            <button className='btn btn-secondary m-3' onClick={(e) => {
                                e.preventDefault();
                                setAddRedirection(false)
                            }}>Annuler</button>
                        </div>
                    </form>
                    : null
            }
            {
                updateRedirection ?
                    <form className='addGroupForm' onSubmit={(e) => {
                        e.preventDefault();

                        axios({
                            method: 'post',
                            url: 'http://prod.maplaque-nfc-link.fr/api/appProd.php',
                            data: {
                                name: nameToUp,
                                category: typeToUp,
                                url: urlToUp,
                                id: redirectionToUp.id,
                                group_id: groupToUp,
                                updateRedirection: 'updateRedirection'
                            },
                            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                        })
                            .then(response => {
                                console.log('Redirection ajouté');
                                setRedirectionAdded(true);
                                setUpdateRedirection(false);
                            })
                            .catch(error => {
                                console.error('Error fetching data:', error);
                            });

                    }}>
                        <h3>Modifier une redirection</h3>
                        <div className='form-group'>
                            <label htmlFor="name">Nom</label>
                            <input className='form-control' type="text" name="name" id="name" onChange={(e) => {
                                setNameToUp(e.target.value);
                            }} defaultValue={redirectionToUp.name} />
                        </div>
                        <div className='form-group'>
                            <label htmlFor="type">Type</label>
                            <select className="form-control" name="type" id="type" onChange={(e) => {
                                setTypeToUp(e.target.value);
                            }}>
                                <option selected value={redirectionToUp.category}>-- {redirectionToUp.category} --</option>
                                <option value="Perso">Plaque</option>
                                <option value="Perso">Perso</option>
                                <option value="Google">Google</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Snapchat">Snapchat</option>
                                <option value="TikTok">TikTok</option>
                                <option value="WhatsApp">WhatsApp</option>
                                <option value="Linkedin">Linkedin</option>
                                <option value="Youtube">Youtube</option>
                                <option value="Site">Site internet</option>
                                <option value="Immodvisor">Immodvisor</option>
                                <option value="Trustpilot">Trustpilot</option>
                                <option value="RDV">Prise de RDV</option>
                                <option value="Wifi">Wifi</option>
                                <option value="Doctolib">Doctolib</option>
                                <option value="Tripadvisor">Tripadvisor</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <label htmlFor="group">Groupe</label>
                            <select className="form-control" name="group" id="group" onChange={(e) => {
                                setGroupToUp(e.target.value);
                            }}>
                                {
                                    groups.map((item, i) => (
                                        item.id === redirectionToUp.group_id ? <option value={item.id} key={i} selected >{item.name}</option> : <option value={item.id} key={i}>{item.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='form-group'>
                            <label htmlFor="url">Url de redirection</label>
                            <input className='form-control' type="url" name="url" id="url" onChange={(e) => {
                                setUrlToUp(e.target.value);
                            }} defaultValue={redirectionToUp.new_url} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button className='btn btn-success m-3'>Modifier</button>
                            <button className='btn btn-secondary m-3' onClick={(e) => {
                                e.preventDefault();
                                setUpdateRedirection(false)
                            }}>Annuler</button>
                        </div>
                    </form>
                    : null
            }
        </div>
    );
};

export default RedirectionList;